import {
  createStore as _createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from "redux";
import {
  addAssetManagerNewsEpic,
  editAssetManagerNewsEpic,
  fetchAssetManagerEpic,
  fetchAssetManagerFundsEpic,
  fetchAssetManagerNewsEpic,
  fetchAssetManagersEpic,
  fetchSingleAssetManagerNewsEpic,
  fetchTopAssetManagerFundsEpic,
  removeAssetManagerNewsEpic,
  saveAssetManagerEpic,
  fetchMacroOverviewsEpic,
} from "./domain/assetManagers";
import {
  addConferenceEpic,
  editConferenceEpic,
  fetchConferencesListEpic,
  fetchSingleConferenceEpic,
  registerForConferenceEpic,
  sendConferenceParticipationConfirmationEpic,
  sendConferenceRequestEpic,
  unregisterFromConferenceEpic,
} from "./domain/conferences";
import {
  addFundDocumentEpic,
  editFundDocumentEpic,
  fetchFundDocumentsEpic,
  removeFundDocumentEpic,
} from "./domain/funds/fundDocuments";
import {
  addFundToWatchlistEpic,
  deleteFundFromWatchlistEpic,
  fetchWatchlistFundsEpic,
} from "./domain/funds/watchlist";
import {
  cancelSubscriptionEpic,
  fetchLicensesEpic,
  redirectToPaymentEpic,
} from "./domain/licenses";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import {
  confirmUserEpic,
  cookies,
  fetchLicenseEpic,
  fetchUserWebinarsEpic,
  getLoggedInUserEpic,
  loginEpic,
  logoutEpic,
  refreshTokenEpic,
  registerEpic,
  rejectTokenEpic,
  requestResetPasswordEpic,
  resetPasswordEpic,
  setReceiveEmailsEpic,
  wholesaleRegisterEpic,
} from "./domain/auth";
import { connectRouter, push, routerMiddleware } from "connected-react-router";
import {
  fetchActiveChartsEpic,
  fetchChartDataEpic,
  fetchComparisonChartDataEpic,
  setChartsLoadingEpic,
} from "./domain/charts";
import {
  fetchAdvancedSearchFundsEpic,
  fetchCompareFundsEpic,
  fetchFundsInPeergroupEpic,
  fetchNextSearchFundsEpic,
  fetchSearchFundsEpic,
  fetchSelectedFundEpic,
} from "./domain/funds/search";
import {
  fetchAifFundsEpic,
  fetchFundAifNewsEpic,
  fetchPaidDocumentEpic,
  saveFundEpic,
} from "./domain/funds/aif";
import {
  fetchCustomFieldsEpic,
  fetchFiltersEpic,
  saveCustomFieldsEpic,
} from "./domain/filters";
import {
  fetchEmpFundsEpic,
  fetchEsgFundsEpic,
} from "./domain/funds/fundsPremium";
import {
  fetchEmpPeergroupsEpic,
  fetchEsgPeergroupsEpic,
  fetchExclusionsEpic,
  fetchPremiumSearchFundsEpic,
} from "./domain/premium";
import {
  fetchFilterSearchCountEpic,
  fetchFilterSearchFundsEpic,
  fetchFilterSearchPerfRiskEpic,
} from "./domain/funds/filterSearch";
import { fetchHomeFactsEpic, fetchHomeNewsEpic } from "./domain/home";
import { fetchNewsListEpic, fetchSingleNewsEpic } from "./domain/news";
import { assetManagerSummaryExportEpic, esgCompanySummaryExportEpic, esgFundSummaryExportEpic, fetchQuestionsCountEpic, fetchSurveyEpic, fetchSurveysVersionsEpic, sendSurveyEpic, sendSurveyVersionEpic, surveyExportEpic, uploadArticle89Epic } from "./domain/survey";
import { fetchRecordDateEpic, setCookieInfoVisibility } from "./ui/app";
import { fetchReportsListEpic, fetchSingleReportEpic } from "./domain/reports";
import {
  fetchSavedSearchesEpic,
  removeSearchEpic,
  saveSearchEpic,
} from "./domain/savedSearches";
import {
  generateExportFileEpic,
  incrementStatsEpic,
} from "./domain/funds/common";
import { getFilterSearchQueryParams, onSearchChange } from "../utils/routing";
import { getLangUrl, isProd } from "../utils/app";
import {
  sendAmRequestEpic,
  sendFundRequestEpic,
  sendMessageEpic,
  sendPremiumMessageEpic,
  sendTeamRequestEpic,
  subscribeListEpic,
  unsubscribeEpic,
  unsubscribeListEpic,
} from "./domain/contact";
import { setPrevPathname, setPrevSearch } from "./domain/route";

import { createBrowserHistory } from "history";
import { domainReducers } from "./domain/index";
import { fetchAssetManagerStatsEpic } from "./domain/assetManagersCharts";
import { fetchFundEpic } from "./domain/funds/details";
import { fetchFundsInFocusEpic } from "./domain/funds/fundsInFocus";
import { fetchRatingInFocusEpic } from "./domain/funds/ratingInFocus";
import { reducer as formReducer } from "redux-form";
import { onRouteChange } from "../utils/routing";
import { setFUndsListModalVisibility } from "./ui/fundsList";
import { sxAuth } from "../utils/sxAuth";
import { uiReducers } from "./ui";

export const history = createBrowserHistory({ basename: "/" });

export const rootReducers = combineReducers<any>({
  ui: uiReducers,
  domain: domainReducers,
  router: connectRouter(history),
  form: formReducer,
});

const epicMiddleware = createEpicMiddleware();

const composeEnhancers =
  typeof window === "object" &&
    !isProd() &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const epics = combineEpics(
  fetchFundEpic,
  fetchCompareFundsEpic,
  fetchFundsInFocusEpic,
  fetchRatingInFocusEpic,
  fetchSearchFundsEpic,
  fetchAdvancedSearchFundsEpic,
  fetchNewsListEpic,
  fetchReportsListEpic,
  fetchHomeNewsEpic,
  fetchHomeFactsEpic,
  fetchSingleNewsEpic,
  fetchSingleReportEpic,
  loginEpic,
  logoutEpic,
  registerEpic,
  wholesaleRegisterEpic,
  refreshTokenEpic,
  confirmUserEpic,
  requestResetPasswordEpic,
  resetPasswordEpic,
  sendMessageEpic,
  sendPremiumMessageEpic,
  fetchFiltersEpic,
  fetchSelectedFundEpic,
  rejectTokenEpic,
  addFundToWatchlistEpic,
  deleteFundFromWatchlistEpic,
  fetchWatchlistFundsEpic,
  fetchChartDataEpic,
  fetchActiveChartsEpic,
  fetchEmpFundsEpic,
  fetchEsgFundsEpic,
  fetchFundsInPeergroupEpic,
  fetchEmpPeergroupsEpic,
  fetchEsgPeergroupsEpic,
  fetchExclusionsEpic,
  fetchSavedSearchesEpic,
  saveSearchEpic,
  removeSearchEpic,
  saveCustomFieldsEpic,
  fetchCustomFieldsEpic,
  fetchComparisonChartDataEpic,
  sendFundRequestEpic,
  sendTeamRequestEpic,
  subscribeListEpic,
  unsubscribeListEpic,
  unsubscribeEpic,
  fetchPremiumSearchFundsEpic,
  fetchRecordDateEpic,
  fetchAssetManagersEpic,
  fetchMacroOverviewsEpic,
  fetchAssetManagerFundsEpic,
  fetchAssetManagerEpic,
  fetchAssetManagerNewsEpic,
  fetchTopAssetManagerFundsEpic,
  incrementStatsEpic,
  saveAssetManagerEpic,
  fetchSingleAssetManagerNewsEpic,
  fetchFilterSearchFundsEpic,
  fetchFilterSearchCountEpic,
  generateExportFileEpic,
  sendAmRequestEpic,
  fetchFilterSearchPerfRiskEpic,
  fetchAssetManagerStatsEpic,
  addAssetManagerNewsEpic,
  editAssetManagerNewsEpic,
  removeAssetManagerNewsEpic,
  addFundDocumentEpic,
  fetchFundDocumentsEpic,
  editFundDocumentEpic,
  removeFundDocumentEpic,
  saveFundEpic,
  fetchConferencesListEpic,
  fetchSingleConferenceEpic,
  addConferenceEpic,
  editConferenceEpic,
  registerForConferenceEpic,
  sendConferenceParticipationConfirmationEpic,
  sendConferenceRequestEpic,
  redirectToPaymentEpic,
  fetchUserWebinarsEpic,
  unregisterFromConferenceEpic,
  fetchNextSearchFundsEpic,
  fetchLicensesEpic,
  cancelSubscriptionEpic,
  fetchLicenseEpic,
  fetchFundAifNewsEpic,
  fetchAifFundsEpic,
  fetchPaidDocumentEpic,
  setChartsLoadingEpic,
  setReceiveEmailsEpic,
  sendSurveyEpic,
  sendSurveyVersionEpic,
  fetchSurveyEpic,
  fetchQuestionsCountEpic,
  getLoggedInUserEpic,
  surveyExportEpic,
  uploadArticle89Epic,
  assetManagerSummaryExportEpic,
  fetchSurveysVersionsEpic,
  esgFundSummaryExportEpic,
  esgCompanySummaryExportEpic,
);

export const store = _createStore(
  rootReducers,
  composeEnhancers(applyMiddleware(epicMiddleware, routerMiddleware(history)))
);

const handleRouteChange = ({ pathname, search }) => {
  const prevPathname = store.getState()["domain"]["route"]["prevPathname"];
  const prevSearch = store.getState()["domain"]["route"]["prevSearch"];

  setTimeout(() => {
    store.dispatch(setPrevPathname(pathname));
    store.dispatch(setPrevSearch(search));

    if (prevPathname.includes('/details') && pathname.includes('/details')) {
      onRouteChange(pathname, search, store);
    } else if (prevPathname !== pathname && prevSearch !== search) {
      onRouteChange(pathname, search, store);
    } else if (prevPathname !== pathname) {
      onRouteChange(pathname, search, store);
    } else if (prevSearch !== search) {
      onSearchChange(pathname, search, store);
    } else if (prevSearch === search && search.indexOf("selectedFund") !== -1) {
      onSearchChange(pathname, search, store);
    }
  }, 1);
}

history.listen(handleRouteChange);

handleRouteChange(history.location)

epicMiddleware.run(epics);

sxAuth();

if (cookies.get("accept_cookies")) {
  store.dispatch(setCookieInfoVisibility(false));
}

if (cookies.get("dont_show_modal")) {
  store.dispatch(setFUndsListModalVisibility(false));
}

export const filterSearchSubmit = () => {
  const form = store.getState().form.filterSearch;
  if (form && form.values) {
    const queryParams = getFilterSearchQueryParams(store.getState().form);
    store.dispatch(push(`${getLangUrl()}/filter-search-results${queryParams}`));
  }
};

export default () => store;